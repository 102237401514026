import axios from 'axios'

// AUDE EXAM

export function audeExamInfo () {
  const responde = axios.get('/v1/aude-exam-info')
  return responde
}

export function getAudeExam (id) {
  return axios.get('/v1/aude-exams/' + id)
}

export function getAudeExamAnswerCard (answerSheetId) {
  return axios.get('/v1/aude-exam-answer-sheet/' + answerSheetId + '?with=audeExamAnswers.audeExamQuestion')
}

export function getAudeExamWithAnswerCard (audeExamId, roleId) {
  return axios.get('/v1/role-aude-exams/' + audeExamId + '?answering_role_id=' + roleId)
}

export function createAudeExamAnswerSheets (audeExamId, data) {
  return axios.post('v1/aude-exams/' + audeExamId + '/aude-exam-answer-sheets', data)
}

export function getAudeExamQuestionGroups (audeExamId, withQuestions, foreignLanguage) {
  let url = '/v1/aude-exams/' + audeExamId + '/aude-exam-groups'
  if (withQuestions) {
    url += '?with=audeExamQuestions'
  }
  if (foreignLanguage > 0) {
    url += '&foreign_language=' + foreignLanguage
  }
  return axios.get(url)
}

export function setAudeExamAnswer (answerSheetId, data) {
  return axios.post('/v1/aude-exam-answer-sheet/' + answerSheetId + '/aude-exam-answers', data)
}

export function finishAudeExam (answerSheetId) {
  return axios.post('/v1/aude-exam-answer-sheet/' + answerSheetId + '/finish')
}

export function audeExamResults (roleId) {
  return axios.get('/v1/aude-exams-results?role_id=' + roleId)
}

export default {
  audeExamInfo,
  getAudeExam,
  createAudeExamAnswerSheets,
  getAudeExamAnswerCard,
  getAudeExamQuestionGroups,
  setAudeExamAnswer,
  finishAudeExam,
  getAudeExamWithAnswerCard,
  audeExamResults
}
