<template>
  <button
    class="au-button-icon"
    @click="insideClick"
  >
    <svg-icon
      v-if="icon"
      type="mdi"
      :path="icon"
    />
  </button>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon'
export default {
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    // label: {
    //   type: String,
    //   required: true
    // },
    to: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    insideClick () {
      if (this.to) {
        this.$router.push(this.to)
        return false
      }
      this.$emit('click')
    }
  }
}
</script>
