<template>
  <b-modal
    id="modal-Terms"
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-stacking
    scrollable
    header-class="border-0"
    footer-class="border-0"
    v-on="$listeners"
  >
    <template #modal-header>
      <h5 class="font-weight-bolder mt-4 ml-4">
        Termos de uso e políticas de privacidade
      </h5>
    </template>
    <div
      class="row rounded d-flex justify-content-center mr-1 ml-1"
      style="background-color: lightgray;"
    >
      <div
        class="col-11 mt-4 mb-4"
        style="overflow-y: scroll; max-height: 300px"
      >
        <p class="font-weight-bolder">
          POLÍTICA DE PRIVACIDADE DA PLATAFORMA E DO APLICATIVO DA AUDE EDUCAÇÃO
        </p>
        <p>
          Privacidade é um tema sério e a Aude (Editora Brasil Sabendo S.A.) trata dele com a relevância que merece. Pensando nisso, elaboramos uma Política de Privacidade e Proteção de Dados Pessoais (“Política de Privacidade”) que estabelece as regras sobre obtenção, armazenamento, transmissão, uso, tratamento e eliminação dos dados pessoais coletados dentro do escopo de funcionalidades da plataforma digital (incluindo o aplicativo para dispositivos móveis) da Aude, de acordo com a Lei 13.709, de 14 de agosto de 2018 (“LGPD”).
        </p>
        <p>
          Todos os dados relacionados ao uso da plataforma da Aude estão sujeitos à Política de Privacidade aqui estipulada.
        </p>
        <p>
          As expressões utilizadas nesta Política de Privacidade, tais como, ‘titular dos dados’, ‘dados pessoais’, ‘tratamento’, e outras que sejam termos definidos na LGPD, serão interpretadas com o significado atribuído a elas na LGPD.
        </p>
        <span class="font-weight-bolder">Cadastro na Plataforma e Obtenção de Dados</span>
        <p>
          Para viabilizar o seu acesso à plataforma digital, coletamos determinados dados pessoais que são preenchidos por você, titular dos dados, ao criar um usuário. A plataforma solicita informações como nome completo, número de identidade e CPF, e-mail de contato, endereço, instituição de ensino, ano e criação de senha de acesso, podendo solicitar ainda outras informações, de modo a identificar login e envio de informações personalizadas e/ou particulares, conforme o plano de estudos que for aplicável a você. A plataforma também coleta informações preenchidas pelo usuário em formulários dinâmicos e no uso das funcionalidades da plataforma, como por exemplo ao realizar as atividades escolares, carregar material didático e exercícios, dentre outros.
        </p>
        <p>
          Adicionalmente, a plataforma pode coletar informações para identificar o tipo de sistema operacional, modelo e fabricante do dispositivo utilizado, navegador, IP e local de acesso. Esses dados são coletados para auxiliar no suporte a problemas, verificação de base instalada e estatísticas de utilização.
        </p>
        <p>
          Ao usar a plataforma da Aude, você concorda que os dados que informar: (i) não são e não serão fraudulentos; (ii) não infringem nenhum direito autoral de terceiros, patente, marca registrada, direito de distribuição ou outro direito de propriedade intelectual, de publicação ou privacidade; (iii) não violam nenhuma lei, estatuto, ordenação ou regulamento; (iv) não são e não serão difamatórios, caluniosos, ameaçadores ou abusivos; (v) não serão obscenos ou conterão pornografia, pornografia infantil, ou fotos de pessoas despidas; (vi) não devem conter vírus, cavalos de tróia, worms, time bombs, cancelbots, easter eggs ou outras rotinas de programação que possam danificar, interferir, interceptar ou desapropriar qualquer sistema, dado ou informação pessoal; (vii) não devem nos responsabilizar ou fazer com que percamos (total ou parcialmente) o serviço do nosso provedor de internet ou outros fornecedores; e/ou (ix) não devem criar links direta ou indiretamente a qualquer material que você não tenha direito de incluir ou linkar.
        </p>
        <span class="font-weight-bolder">Finalidade</span>
        <p>
          As finalidades para o tratamento dos seus dados pela Aude são: (i) viabilizar o seu acesso à plataforma digital e permitir o adequado para o funcionamento da plataforma no aparelho em que você usar; (ii) fornecer, personalizar e customizar os produtos e serviços que forem contratados por ou para você, melhorando a sua experiência na plataforma; (iii) aprimorar nossa plataforma e aplicativo; (iv) viabilizar um satisfatório funcionamento dos serviços que nos propomos a prestar e dos nossos produtos, melhorando o que oferecemos aos nossos usuários; (v) informar os usuários sobre as atualizações disponíveis, ofertas, promoções e campanhas de publicidade de nossos serviços (sejam serviços atualmente fornecidos ou serviços que venhamos a desenvolver e a fornecer no futuro) e de serviços fornecidos ou a serem fornecidos no futuro por nossos parceiros; e (vi) desenvolver novos produtos e/ou serviços, sejam eles pela própria Aude, por qualquer sociedade afiliada da Aude ou qualquer empresa parceira, utilizando de tais dados como ferramenta estatística ou experimental, incluindo, sem limitação, os dados de acesso, visualizações, provas e simulados realizados e seus resultados, redações, interações de todas e quaisquer naturezas do usuário com a plataforma.
        </p>
        <span class="font-weight-bolder">Consentimento</span>
        <p>
          Por meio da utilização da nossa plataforma digital, você concorda expressamente com o tratamento dos seus dados pessoais pela Aude e com o compartilhamento desses dados pessoais com nossos parceiros comerciais para o oferecimento de serviços ou produtos, observado que tais parceiros deverão sempre respeitar a Política de Privacidade, na forma que estiver em vigor. Ao prosseguir com a utilização da plataforma, você ratificará todos os termos e condições da Política de Privacidade atualmente em vigor. Qualquer alteração à Política de Privacidade será comunicada a você por meio da plataforma, onde constarão as alterações e você será questionado novamente se concorda com os novos termos e condições e se consente com o tratamento dos seus dados pessoais naqueles novos termos e condições. Você somente poderá continuar a usar a plataforma caso concorde com os novos termos e condições.
        </p>
        <p>
          Você consente de forma livre, informada e inequívoca que a Aude ou qualquer de seus afiliados poderá fornecer seus dados pessoais a um ou mais operadores, a fim de viabilizar o tratamento de dados pessoais pela Aude, no Brasil e/ou no exterior.
        </p>
        <p>
          Você garante à Aude o direito não-exclusivo, universal e sem pagamento de royalties de utilizar no Brasil e/ou no exterior, e de exercer todos os direitos de cópia e direitos de base de dados que você possua sobre seus dados, por qualquer meio conhecido ou que venha a ser criado futuramente.
        </p>
        <p>
          Você entende e concorda que a Aude pode divulgar seus dados caso: (i) se verifique legítimo interesse da Aude; (ii) lhe seja requerido por lei ou por acreditar de boa-fé que essa divulgação é razoável e necessária para: (a) cooperar com um procedimento judicial, uma ordem judicial ou processo legal sobre nós ou nossa plataforma; (b) replicar a respeito da infração do direito de terceiros por seus dados; e/ou (c) proteger os direitos, propriedades ou a segurança pessoal da Aude, seus empregados, parceiros, clientes, usuários e público.
        </p>
        <p>
          Os dados serão mantidos pela Aude pelo prazo mínimo de 10 (dez) anos a contar do encerramento da sua conta/usuário na plataforma, podendo ser utilizado para as finalidades expressamente previstas na Política de Privacidade, ressalvadas as hipóteses de guarda obrigatória de registros previstas em lei. Informações de pagamento serão mantidas em nossa base de dados para futuras referencias contábeis. Esse parágrafo não se aplicará aos dados que o titular requerer a exclusão.
        </p>
        <span class="font-weight-bolder">Dúvidas e Exclusão</span>
        <p>
          Quaisquer dúvidas ou requerimentos relacionados ao tratamento de seus dados pela Aude, inclusive para fins de exclusão da base da nossa plataforma, deverão ser encaminhados diretamente à Aude.
        </p>
        <span class="font-weight-bolder">Política de Cookies</span>
        <p>
          A Aude usa cookies, tags e outras tecnologias similares para melhorar a experiência do usuário na plataforma e para fornecer-lhe informações adequadas às suas necessidades.
        </p>
        <p>
          Os cookies são utilizados para armazenar as preferências do usuário em seu computador, smartphone, celular ou tablet e oferecer um serviço personalizado. Eles servem para reconhecer, acompanhar seus hábitos de navegação e alertar os visitantes sobre novas áreas que podem ser de seu interesse cada vez que retornarem ao nosso aplicativo.
        </p>
        <p>
          Durante a sua navegação na plataforma, é possível que cookies anônimos ou tecnologias similares sejam instalados no seu computador, smartphone, celular ou tablet.
        </p>
        <p>
          Você poderá recusar os cookies caso não tenha interesse em que trabalhemos para a melhora da sua experiência. Consulte as instruções de seu navegador para saber mais sobre cookies e sobre como desabilitá-los.
        </p>
      </div>
    </div>
    <template #modal-footer>
      <b-form-checkbox
        id="checkbox-1"
        v-model="acceptTerms"
        name="checkbox-1"
      >
        Li e aceito os termos de uso e políticas de privacidade citados acima.
      </b-form-checkbox>
      <AuButton
        class="ml-4 mr-4 au-button-primary"
        label="Aceito"
        :disabled="acceptTerms === false ? true : false"
        @click="acceptTermsOfUse"
      />
      <AuButton
        class="au-button-secondary"
        label="Recusar"
        @click="$emit('recuseTerms')"
      />
    </template>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'
export default {
  model: BModal.options.model,

  data: () => ({
    acceptTerms: false
  }),

  methods: {
    async acceptTermsOfUse () {
      try {
        await this.$api.acceptTerms()
        await this.$api.me()
        this.$emit('close')
      } catch (e) {
        this.$emit('error', e.response)
      }
      this.$emit('close')
    }
  }
}
</script>
