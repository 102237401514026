<template>
  <div
    v-if="showAlert"
  >
    <b-overlay
      :show="loading"
      rounded="lg"
    >
      <div
        class="center p-2"
        dismissible
        style="color: #0c5460; background-color:#d1ecf1; font-size: 14px!important; line-height: 1.2;"
      >
        Enviamos um e-mail de confirmação para {{ user.email }}.
        <br>
        Siga as instruções na mensagem para verificar seu endereço. Não recebeu?
        <b-link
          class="font-weight-bold"
          @click="reSemd"
        >
          clique aqui
        </b-link>
      </div>
    </b-overlay>
    <b-modal
      id="modal-FeedBack"
      v-model="modalFeedBack"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer
      centered
      no-stacking
      size="md"
    >
      <div style="text-align: center;">
        <img
          class="mt-20 mb-10"
          src="@/assets/email_enviado.svg"
          width="170px"
        >
        <br>
        Enviamos para você um e-mail com instruções para confirmar seu cadastro
        <AuButton
          class="mt-4 au-button-primary au-button-block"
          label="OK"
          @click="closeAll"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import utils from '@/api/utils.js'

export default {

  mixins: [
    handlesServerValidation
  ],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showAlert: false,
    loading: false,
    modalFeedBack: false,
    user: {}
  }),

  watch: {
    show () {
      this.showAlert = this.show
    }
  },

  created () {
    this.user = utils.getUser()
    if (this.user.email_verified_at === null) {
      this.showAlert = true
    }
  },

  methods: {
    async reSemd () {
      this.loading = true
      const data = {
        email: this.user.email
      }
      try {
        await this.$api.reSend(data)
      } catch (e) {
        this.getErrors(e)
      }
      this.loading = false
      this.modalFeedBack = true
    },
    closeAll () {
      this.showAlert = false
      this.modalFeedBack = false
    }
  }

}
</script>
