<template>
  <b-modal
    id="modal-contract-unavailable"
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    no-stacking
    v-on="$listeners"
  >
    <div
      class="text-center"
      style="color: gray"
    >
      <img
        class="mt-10"
        src="@/assets/disponivel_em_breve.svg"
        style="max-width: 120px"
      >
      <p class="mb-10 mt-10 ml-5 mr-5">
        {{ msgModal }}
      </p>
      <AuButton
        class="mb-6 au-button-primary au-button-block"
        label="OK"
        @click="$emit('close')"
      />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'

export default {
  model: BModal.options.model,

  props: {
    msgModal: { type: String, default: '' }
  }
}
</script>
