<template>
  <button
    class="d-flex flex-row justify-content-center align-items-center au-button"
    :style="`${icon? 'padding: 0 16px 0px 12px!important;' : ''}`"
    @click="insideClick"
  >
    <svg-icon
      v-if="icon"
      type="mdi"
      class="button-icon"
      :path="icon"
    />
    {{ label }}
  </button>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon'
export default {
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    insideClick () {
      if (this.to) {
        this.$router.push(this.to)
        return false
      }
      this.$emit('click')
    }
  }
}
</script>
