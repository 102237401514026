<template>
  <b-modal
    id="modal-no-school-course"
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    no-stacking
    v-on="$listeners"
  >
    <div
      class="mt-3 mb-10 text-center"
    >
      <img
        src="@/assets/nenhuma_turma.svg"
        style="max-width: 180px"
      >
    </div>
    <div
      class="mt-3 text-center"
    >
      <p> Ops! você ainda não foi matriculado em nenhuma turma. </p>
      <p> Entre em contato com a administração de sua escola para mais informações. </p>
      <AuButton
        class="au-button-primary au-button-block"
        label="Entendi"
        @click="$emit('close')"
      />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'
export default {
  model: BModal.options.model
}
</script>
